import React, { useEffect, useContext, useRef } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
// import { TweenLite, Power3 } from "gsap";

import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import Clients from "../../components/common/Clients";
import InteractiveVideo from "../../components/common/InteractiveVideo";
// import image from "../../assets/img/products/Flip Cards Main.jpg";

import { StaticImage } from "gatsby-plugin-image";

const FlipCards = () => {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  // useEffect(() => {
  //   if (!state.showPageLoading) {
  //     TweenLite.to(container, 1, {
  //       opacity: 1,
  //       y: 0,
  //       ease: Power3.easeInOut,
  //       delay: state.containerAnimationDelay,
  //     });
  //   }
  // }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Layout>
      <Seo
        title='Flip Cards | VIRSAT - Virtual Reality Safety Training'
        description='To train workers in essential rules or practices, a flip card system will help the workers to train and test their own knowledge. It is also fun to do.'
      />
      <PageBanner title='Flip Cards' subtitle='Train workers in rules' />
      <section
        className='main-container single-product hide-container'
        ref={container}
      >
        <div className='row top-pad bottom-pad fd-col'>
          <div className='column center fj-center'>
            <div className='image-container'>
              {/* <img src={image} alt='Pre-job discussions' /> */}
              <StaticImage
                src='../../assets/img/products/Flip Cards Main.jpg'
                alt='Flip Cards'
                placeholder='blurred'
                objectFit
              />
            </div>
            <p className='reduce_width no-mar' style={{ marginTop: "30px" }}>
              To train workers in essential rules or practices, a flip card
              system will help the workers to train and test their own
              knowledge. It is also fun to do.
            </p>
          </div>
        </div>
      </section>
      <Clients />
      <InteractiveVideo />
    </Layout>
  );
};

export default FlipCards;
